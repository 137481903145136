<template>
	<!-- 右侧浮窗 -->
	<div class="rightpontoon">
		<div class="pontoonItem" v-if="data" @click="toCart">
			<img src="@/views/images/cart_icon.png" />
			<p>购物车</p>
			<p class="cartNum" v-if="cartNum">{{cartNum}}</p>
		</div>
		<div class="pontoonItem" @click="gotoHelpcenter">
			<img src="@/views/images/instructions_icon.png" />
			<p>帮助中心</p>
		</div>
		<el-popover
			placement="left-start"
			width="200"
			trigger="hover">
			<div class="qrcode" ref="qrCodeUrl"></div>
			<p class="qrcodeText">使用微信或手机浏览器扫码</p>
			<div class="pontoonItem" slot="reference">
				<img src="@/views/images/ewm_icon.png" />
				<p>扫码登录</p>
			</div>
		</el-popover>
		<div class="pontoonItem" @click="goQQ">
			<img src="@/views/images/customer_icon.png" />
			<p>咨询客服</p>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	import Bus from '/src/views/js/bus.js'; 
	export default {
		props:{
			data:{
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				cartNum:0,
				configInfo:null,//配置信息
			}
		},
		methods: {
			//打开QQ
			goQQ(){
				if(this.configInfo.con_qq){
					window.open('http://wpa.qq.com/msgrd?v=3&uin=' + this.configInfo.con_qq + '&site=在线客服&menu=yes','_brank');
				}
			},
			//生成手机端登录二维码
			creatQrCode() {
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: window.location.origin + "/mobile",
					width: 176,
					height: 176,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
			//购物车数量
			getCartNum(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Carts/index.html", {
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.cartNum =  response.data.data.total;//购物车数量
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//跳转购物车
			toCart(){
				if(this.PublicJs.isExam){
					this.$confirm('您当前正在考试，离开即交卷，确定要离开?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						//交卷
						this.$parent.handPapers()
					}).catch(() => {
					           
					});
				}else{
					this.$router.push({
						path: "/myCart"
					});
				}
			},
			//跳转帮助中心
			gotoHelpcenter(){
				this.$emit('goto-helpcenter');
			},
		},
		created(){
			if(this.data){
				//购物车数量
				this.getCartNum()
			}
			//配置信息
			this.configInfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('configInfo')))
		},
		mounted() {
			this.creatQrCode();//生成手机端登录二维码
			Bus.$on('busCartNum', (val) => { //购物车数量
				this.cartNum = val;
			});
		},
	}
</script>

<style scoped="scoped">
	/* 右侧浮窗 */
	.rightpontoon{
		position: fixed;
		right: 10px;
		top: 30%;
		z-index: 999;
	}
	.pontoonItem{
		width: 80px;
		height: 80px;
		background-color: #FFF;
		border-radius: 8px;
		-moz-box-shadow:1px 1px 6px rgba(153,153,153,0.18); 
		-webkit-box-shadow:1px 1px 6px rgba(153,153,153,0.18); 
		box-shadow:1px 1px 6px rgba(153,153,153,0.18); 
		margin-bottom: 11px;
		text-align: center;
		font-size: 14px;
		color: #999999;
		padding-top: 10px;
		cursor: pointer;
	}
	.ewmImg{
		width: 176px;
		height: 176px;
	}
	.cartNum{
		position: absolute;
		top: 8px;
		background-color: #FF4400;
		left: 44px;
		color: #fff;
		padding: 0 7px;
		border-radius: 10px;
		height: 20px;
		line-height: 20px;
	}
	.qrcodeText{
		text-align: center;
		padding-top: 15px;
	}
</style>
