<template>
	<div class="footer">
		<div class="footer_div" v-if="configInfo">
			<div class="footer_box_left">
				<p class="firstP" v-if="configInfo.con_zbphone">值班电话：{{configInfo.con_zbphone}}（工作时间：9:00-18:00)</p>
				<p class="firstP" v-else>客服电话：{{configInfo.con_telphone}}（工作时间：9:00-18:00)</p>
				<p>培训服务：贵州电子信息职业技术学院</p>
				<p><!-- <a href="http://www.borain.com/" target="_blank"> -->技术支持：重庆鸿新在线信息技术有限公司 <!-- </a> --></p>
				<p>
					<span>ICP备案：<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{configInfo.con_icp}}</a></span>
					<span>
						<img src="@/views/images/footer_01.png" class="wan" />
						<a class="itemSpan" target="_blank" :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode='+configInfo.con_wacp">陕公网安备 {{configInfo.con_wacp}} 号</a>
					</span>
				</p>
			</div>
			<div class="footer_box_right">
				<div class="footer_box_right_item" v-if="configInfo.con_wechat_qcode">
					<img :src="configInfo.con_wechat_qcode" alt="" />
					<p>公众号</p>
				</div>
				<div class="footer_box_right_item" v-if="configInfo.con_min_qcode">
					<img :src="configInfo.con_min_qcode" alt="" />
					<p>小程序</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js';  
	export default {
		data() {
			return {
				configInfo:null,//配置信息
				isMian:true,//是否为省平台
				cityName:"",//选择的市
			}
		},
		created() {
			//配置信息
			this.configInfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('configInfo')));
			// if(window.location.host != this.PublicJs.zzUrl){
			// 	this.isMian = false;
			// 	this.cityName = this.$cookies.get("cityName")
			// }			
		},
	}
</script>

<style scoped="scoped">
	.footer {
		width: 100%;
		height: 200px;
		background: #444444;
		min-width: 1200px;
	}

	.footer_div {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		padding-bottom: 30px;
		box-sizing: border-box;
	}

	.footer_div:after {
		display: block;
		content: "";
		clear: both;
	}

	.footer_box_left {
		float: left;
	}

	.footer_box_left p {
		color: #ffffff;
		margin-top: 18px;
		font-size: 13px;
		font-weight: 600;
		font-family: "微软雅黑";
	}

	.footer_box_left p:nth-child(1) {
		margin-top: 30px;
	}

	.footer_box_left p a {
		color: #ffffff;
	}

	.footer_box_left p.firstP {
		font-size: 18px;
	}

	.footer_box_left p span {
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;
	}

	.footer_box_left img {
		margin-right: 10px;
	}

	.footer_box_left p .itemSpan {
		display: inline-block;
		vertical-align: top;
	}

	.footer_box_right {
		float: right;
		padding-top: 27px;
	}

	.footer_box_right_item {
		display: inline-block;
		margin-left: 30px;
		width: 120px;
	}

	.footer_box_right_item img {
		width: 120px;
		height: 120px;
		margin-bottom: 10px;
	}

	.footer_box_right_item p {
		text-align: center;
		color: #ffffff;
		font-size: 14px;
		font-family: "微软雅黑";
	}
	.wan{
		display: inline-block;
		width: 16px;
		vertical-align: top;
	}
</style>
